
	img {
		vertical-align: middle;
		max-width: 100%;
		height: auto;
		width: 100%;
	}
	img[class*="align"],
	img[class*="wp-image-"] {
    	height: auto; /* Make sure images with WordPress-added height and width attributes are scaled correctly */
	}
	img.size-full {
	    max-width: 100%;
	    //width: auto; /* Prevent stretching of full-size images with height and >>width attributes in IE8 */
	}

	#map_canvas img, .map_canvas img { max-width: none!important; }



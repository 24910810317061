@-webkit-keyframes fadeInRight {
  0% {opacity: 0; -webkit-transform: translateX(20px);}
  100% {opacity: 1; -webkit-transform: translateX(0);}
}

@-moz-keyframes fadeInRight {
  0% {opacity: 0; -moz-transform: translateX(20px);}
  100% {opacity: 1; -moz-transform: translateX(0);}
}

@-ms-keyframes fadeInRight {
  0% {opacity: 0; -ms-transform: translateX(20px);}
  100% {opacity: 1; -ms-transform: translateX(0);}
}

@-o-keyframes fadeInRight {
  0% {opacity: 0; -o-transform: translateX(20px);}
  100% {opacity: 1; -o-transform: translateX(0);}
}

@keyframes fadeInRight {
  0% {opacity: 0; transform: translateX(20px);}
  100% {opacity: 1; transform: translateX(0);}
}

@mixin fadeInRight($duration, $delay, $function, $fill, $visibility) {
  @include animation-name(fadeInRight);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}

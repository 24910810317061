@-webkit-keyframes fadeInLeftBig {
	0% {opacity: 0; -webkit-transform: translateX(-2000px);}
 100% {opacity: 1; -webkit-transform: translateX(0);}
}

@-moz-keyframes fadeInLeftBig {
    0% {opacity: 0; -moz-transform: translateX(-2000px);}
  100% {opacity: 1; -moz-transform: translateX(0);}
}

@-ms-keyframes fadeInLeftBig {
    0% {opacity: 0; -ms-transform: translateX(-2000px);}
  100% {opacity: 1; -ms-transform: translateX(0);}
}

@-o-keyframes fadeInLeftBig {
    0% {opacity: 0; -o-transform: translateX(-2000px);}
  100% {opacity: 1; -o-transform: translateX(0);}
}

@keyframes fadeInLeftBig {
    0% {opacity: 0; transform: translateX(-2000px);}
  100% {opacity: 1; transform: translateX(0);}
}

@mixin fadeInLeftBig($duration, $delay, $function, $fill, $visibility) {
  @include animation-name(fadeInLeftBig);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@-ms-keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@-o-keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@mixin fadeIn($duration, $delay, $function, $fill, $visibility) {
	@include animation-name(fadeIn);
  @include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

input,
select,
textarea {
	padding: lh(1) 9px;
	border: 1px solid darken( $grey, 5% );
	width: 100%;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
	color: darken( $grey, 40% );
	@include font-size(16);
	@include rounded(2px);
	@include box-shadow( 3px, 3px, 8px, rgba(0,0,0,0.1), inset );
}

input[type="checkbox"] {
	@include box-shadow( 0, 0, 0, 0, rgba(0,0,0,0) );
}


::-webkit-input-placeholder {
   color: darken( $grey, 20% );
}
:-moz-placeholder {
   color: darken( $grey, 20% );
}

/* BASIC FORMS */
form {
	label {
		@include font-size(18);
		font-weight: 300;
		padding: lh(0.5) 0 lh(0.25);
		display: block;
		text-align: left;
		line-height: lh(2);
		color: $ls-purple;
	}

}


/* Form Messages */
.msg-module {
	background-color: $msg-module-bg;
	border: 1px solid darken( $msg-module-bg, 5% );
	padding: 1em;
	text-align: center;
	color: darken( $ls-red, 30% );
	@include rounded(4px);

	h3 {
		font-weight: 700;
		@include font-size(16);
	}

	p {
		line-height: lh(2);
		@include font-size(14);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

/* BUTTONS */

input[type="submit"],
button,
.btn {
	white-space: nowrap;
	border: 0;
	display: block;
	border: none;
	background: none;
	color: inherit;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;

	&:focus {
		outline: none;
	}
}

.btn {
	background: darken( $ls-red, 2%);
	line-height: 1em;
	font-weight: 700;
	text-align: center;
	padding-top: 2em;
	padding-bottom: 2em;
	color: #fff;
	text-transform: uppercase;
	margin-bottom: lh();
	@include background-clip( padding-box );
	@include rounded(6px);
	@include font-size(16);
	@include transition( #{"color 0.1s ease-in, background 0.1s ease-in"} );

	&:hover {
		background: darken( $ls-red, 12%);
		//border-color: darken( $diablo-blue, 14%);
		color: #fff;
	}

	@include breakpoint($md) {
		@include font-size(25);
	}

	@include breakpoint($xl) {
		@include font-size(25);
	}

}

.btn-sm {
	display: inline-block;
	padding: 0.5em 1em;
}


/* Nuka Button */
.btn-fancy {
	width: 88%;
	margin-left: auto;
	margin-right: auto;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

	&::before,
	&::after {
		content: '';
		position: absolute;
		border-radius: inherit;
		background: $buttoncolor;
		z-index: -1;
	}

	&::before {
		top: -4px;
		bottom: -4px;
		left: -4px;
		right: -4px;
		opacity: 0.4;
		-webkit-transform: scale3d(0.3, 1, 1);
		transform: scale3d(0.3, 1, 1);
		-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
		transition: transform 0.3s, opacity 0.3s;
		@include rounded(9px);
	}
	&::after {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-transform: scale3d(1.1, 1, 1);
		transform: scale3d(1.1, 1, 1);
		-webkit-transition: -webkit-transform 0.3s, background-color 0.3s;
		transition: transform 0.3s, background-color 0.3s;
	}
	&::before,
	&::after {
		-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	}

	&:hover {
		color: #fff;

		&::before {
			opacity: 1;
		}
		&::after {
			background-color: darken( $buttoncolor, 25% );
		}

		&::after,
		&::before {
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}
	}

	&:active {
		&::after,
		&::before {
			background: $buttoncolor;
		}
	}

	@include breakpoint($xl) {
		&::before {
			-webkit-transform: scale3d(0.2, 1, 1);
			transform: scale3d(0.2, 1, 1);
		}

	}
}


// Form Fileds
.form-fields {
	li {
		margin-bottom: lh();
		@include row();

		&.full {
			div {
				@include column(16);
				&.formErrorContent {
					width: 30%;
				}
			}
		}
		&.half {
			div {
				@include column(8);
			}
		}

		&.two-up {
			div {}
		}

		&.single-label {
			label {
				@include column(16);
			}
		}
	}

	.checkbox-grid {
			.grid-column {
				@include column(13);
				display: block;
				text-align: left;
				float: left;
				padding-top: .5em;
				position: relative;
				label {
					display: inline;
					padding-right: 1em;
					@include font-size(16);
					color: $text-color;
				}
				span {
					@include column(16);
					line-height: lh(3);
				}
				input[type="checkbox"] {
					float: left;
					width: 15px;
					height: 17px;
					margin-right: 13px;
					margin-top: 8px;
				}
			}
		}

}

.legal-agree {
	background-color: $white;
	padding: lh() 1em;

	div {
		padding: lh(0.45) 0.5em lh(0.35) 1.5em;
		float: left;
		position: relative;
		margin: 0.2em 0.5em 0 -1.5em;
		// @include font-size(18);
		@include rounded(4px);

		input {
			width: 16px;
			height: 16px;
			line-height: 0;
		}
	}

	label {
		p {
			line-height: lh(1.75);
			color: darken($grey, 30%);;
			margin-bottom: lh();
			@include font-size(12);
		}
	}

	@include breakpoint($xl) {
		label {
			p {
				line-height: lh(1.75);
				@include font-size(12);
			}
		}
	}
}

.checkbox-grid {
	div {
		.left-col {
			text-align: center;
			display: block;
			float: left;
			position: relative;
			width: 50%;
		}
		.right-col {
			text-align: center;
			display: block;
			float: right;
			position: relative;
			width: 50%;

		}
	}


}


.radios-yes-no {

	div {

		span {
			text-align: center;
			display: inline;
			float: left;
			position: relative;
			text-align: center;
			width: 50%;
			border: 2px solid #f2f2f2;
			border-radius: 15px;
			padding: 20px;
			@include box-shadow( 3px, 3px, 8px, 0, rgba(0,0,0,0.1), true );

			input { width: auto; }
		}
	}

	li {
		@include row();
		margin-bottom: lh(2);

		div {

		}
	}

	.radio-legend {
		font-weight: 700;
		color: $ls-purple;
		@include column(4);
		@include push(12);
		@include font-size(12);


	}
	.radio-label {
		line-height: lh(2);
		@include font-size(16);
		display: block;
		float: none;
	}

	.radio-label-padded {
		padding-top: .5em;
		padding-bottom: .5em;
	}

	.radio-inputs {
		@include column(16);
		margin-top: 10px;
		label {
			padding: 0;
			display: inline;
			@include font-size(16);
			span {
				width: 28%;
				margin-right: 10px;
				font-weight: 600;
			}
			@include breakpoint($sm) {
        		span {
          			width: 40%;
        		}
      		}
      		@include breakpoint($md) {
      			span {
      				width: 34%;
      			}
      		}
      		@include breakpoint($lg) {
        		span {
          			width: 37%;
        		}
      		}
      		@include breakpoint($xl) {
        		span {
          			width: 39%;
        		}
      		}
		}
	}

	.radio-inline {
    	width: 7em;
    	margin-left: 0;
    	-webkit-margin-before: 0;
    	-webkit-margin-after: 0;
    	-webkit-margin-start: 0;
    	-webkit-margin-end: 0;
    	-webkit-padding-start: 0 !important;
	}


	@include breakpoint($xl) {
		.radio-label {
			line-height: lh(3);
			@include font-size(20);
		}
	}
}
.select-form-label {
		line-height: lh(2);
		@include font-size(16);
		display: block;
		float: none;

		@include breakpoint($xl) {
			line-height: lh(3);
			@include font-size(20);
		}
}

.parsley-required, .parsley-custom-error-message {
	color: red;
	padding-left: 2px;
}

.form-header {
	background-color: $body-color;
	margin-bottom: 1.5em;
	text-align: center;
	color: $white;
	font-size: 1.5em;
    line-height: normal;
    margin-top: 1em;
    width: 50%;
    float: right;
}

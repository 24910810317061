
// Variables ========================================

// COLOR ==============================

// Brand Specific Colors
$ls-green: 			rgb( 146, 195, 77 );
$ls-blue: 			rgb( 19, 175, 220 );
$ls-purple: 		rgb( 53, 64, 80 );
$ls-red:            rgb( 228, 59, 44 );

$grey: 				lighten( #666, 50%);
$white:             #fff;

// ROOT ==============================
$body-color: 		#357196;
$text-color: 		darken( $grey, 50% );

//	TYPOGRAPHY ==============================
$base-font: 		'Open Sans', Candara, Calibri, Segoe, "Segoe UI", Optima, Arial, sans-serif;
$base-fontsize: 	16px;
$base-lineheight:  	10px;

$alt-font: 			'Playfair Display', Cambria, Georgia, serif;



// Links
$linkcolor: 		darken( $ls-blue, 15% );
$linkcolorhover:	darken( $ls-blue, 25% );

$buttoncolor:		$ls-red;

// TEXTURE ==============================

$msg-module-bg:		lighten( $ls-green, 40% );


// LAYOUT

// Measurements

//	Grid Variables
$column-width: 		60px;
$gutter-width: 		20px;
$columns: 			16;
// Set $total-width to 100% for a fluid layout
$total-width: 100%;

// Uncomment these two lines and the star-hack width/margin lines below to enable sub-pixel fix for IE6 & 7. See http://tylertate.com/blog/2012/01/05/subpixel-rounding.html
// $min-width: 999999;
// $correction: 0.5 / $min-width * 100;
